<template>
  <div style="display: flex; flex-direction: column; text-align: left; padding: 16px; background-color: #EAF4FF; border-top: solid 1px #E0E0E0;">
    <div class="parentContainer">
      <div>
        <div style="display: flex; flex-direction: row; gap: 8px">
          <img src="https://s3iconimages.mymedicine.com.mm/upcomingSlot.svg" />
          <div style="display: flex; flex-direction: column; margin-top: 4px; text-align: left;">
            <span>{{ $props.activeAppointmentDetails.nurse_type }} Is</span>
            <h5 :style="{ color: currentNurseStatus.color }">{{currentNurseStatus.text}}</h5>
          </div>
        </div>
        <h4 style="font-size: 16px; font-weight: 600; margin-top: 8px;">{{$props.activeAppointmentDetails.nurse_name}}</h4>
      </div>
      <div v-if="$props.activeAppointmentDetails.nurse_status < 1" style="display: flex; flex-direction: column; align-items: end">
        <span>Reach Code</span>
        <v-chip color="#1467BF" label style="color: #FFF; width: 69px; font-size: 16px; text-align: center;">1111</v-chip>
      </div>
      <div v-else style="display: flex; flex-direction: column; align-items: end;">
        <span>Complete Code</span>
        <v-chip color="#27AE60" label style="color: #FFF; width: 69px; font-size: 16px; text-align: center;">0000</v-chip>
      </div>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "appointmentStatus",
  props: ['activeAppointmentDetails'],
  data() {
    return {
      nurseTransitsStatus: {
        0 :{
          text: 'Yet to Start',
          textColor: '#AB6BFB',
          color: '#F0E4FF'
        },
        1 :{
          text: 'on the way',
          textColor: '#48ACEF',
          color: 'rgba(72, 172, 239, 0.20)'
        },
        2 :{
          text: 'on progress',
          textColor: '#48ACEF',
          color: '#EAF4FF'
        },
        3 :{
          text: 'completed',
          textColor: '#EAF4FF',
          color: '#48ACEF'
        }
      },
      currentNurseStatus:{}
    };
  },
  mounted(){
    this.currentNurseStatus = this.nurseTransitsStatus[this.$props.activeAppointmentDetails.nurse_status];
  }
};
</script>
<style scoped>
.parentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span {
  font-size: 12px;
  color: #828282;
}
</style>